import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import VueI18n from 'vue-i18n';
import 'bootstrap';

import Company from './components/pages/Company.vue';
import Contacts from './components/pages/Contacts.vue';
import Galery from './components/pages/Galery.vue';
import Profile from './components/pages/Profile.vue';
import Reference from './components/pages/Reference.vue';

import * as hu from './assets/locales/hu.js';
import * as en from './assets/locales/en.js';

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(VueI18n);

const routes = [
  {
    path: '/',
    redirect: '/company'
  },
  { 
    path: '/company', 
    component: Company 
  },
  { 
    path: '/contacts', 
    component: Contacts 
  },
  { 
    path: '/galery',
    component: Galery
  },
  { 
    path: '/profile',
    component: Profile
  },
  { 
    path: '/reference',
    component: Reference
  }
];

const router = new VueRouter({
  routes
});

const i18n = new VueI18n({
  locale: 'hu',
  fallbackLocale: 'hu',
  messages: {
    en,
    hu
  }, 
});

new Vue({
  render: h => h(App),
  router,
  i18n
}).$mount('#app');
