<template>
  <router-link class="col-10 col-md-2 col-xl-1 menu-item mt-2 mb-2 ml-1 mr-1" style="text-decoration: none; color: inherit;" v-if="routerText" :to="'/' + routerText">
      {{ $t("menu." + itemText) }}
  </router-link>
</template>

<script>
export default {
  name: 'MenuItem',
  props: {
    itemText: String,
    routerText: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .menu-item {
      padding-top: 8px;
      height: 40px;
      width: 100%;
      cursor: pointer;
      border-radius: 10px;
      border-left: 3px solid #000;
      border-right: 3px solid #000;
  }

  .menu-item:hover:not(.router-link-active) {
    font-weight: bold;
    border-left: 3px solid #00b4cd;
    border-right: 3px solid #00b4cd;
  }

  .menu-item.router-link-active {
    font-weight: bold;
    border-left: 3px solid #00b4cd;
    border-right: 3px solid #00b4cd;
    background: url('~@/assets/images/circuits01-small-left.png');
    background-position-x: left;
    background-repeat: no-repeat;
    background-size: contain;
  }
</style>