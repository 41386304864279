<template>
  <div class="pt-4 mb-4">
    <H2Section itemText="pages.galery.mainHeader"/>
    <Section itemText="pages.galery.heavyCurrent"/>
    <VueGallery :id="'heavy-current-galery'" :images="heavyCurrentGalery" :index="hCIndex" @close="hCIndex = null"/>
    <div
      class="image col-3 col-md-2 mt-1 ml-1 mr-1 mb-4"
      v-for="(hCImage, hCImageIndex) in heavyCurrentImages"
      :key="hCImageIndex"
      @click="hCIndex = hCImageIndex"
      :style="{ backgroundImage: 'url(' + require('@/assets/images/galery/heavyCurrent/' + hCImage) + ')'}"
    ></div>
    <Section itemText="pages.galery.materialHandling"/>
    <VueGallery :id="'material-handling-galery'" :images="materialHandlingGalery" :index="mHIndex" @close="mHIndex = null"/>
    <div
      class="image col-3 col-md-2 mt-1 ml-1 mr-1 mb-4"
      v-for="(mHImage, mHImageIndex) in materialHandlingImages"
      :key="mHImageIndex"
      @click="mHIndex = mHImageIndex"
      :style="{ backgroundImage: 'url(' + require('@/assets/images/galery/materialHandling/' + mHImage) + ')'}"
    ></div>
    <Section itemText="pages.galery.water"/>
    <VueGallery :id="'water-galery'" :images="waterGalery" :index="wIndex" @close="wIndex = null"/>
    <div
      class="image col-3 col-md-2 mt-1 ml-1 mr-1 mb-4"
      v-for="(wImage, wImageIndex) in waterImages"
      :key="wImageIndex"
      @click="wIndex = wImageIndex"
      :style="{ backgroundImage: 'url(' + require('@/assets/images/galery/water/' + wImage) + ')'}"
    ></div>
  </div>
</template>

<script>
import VueGallery from 'vue-gallery';
import H2Section from '../elements/H2Section.vue';
import Section from '../elements/Section.vue';
import * as config from '../../assets/config.js';

const heavyCurrentImages = config.galery.images.lists.heavyCurrentImages;
const materialHandlingImages = config.galery.images.lists.materialHandlingImages;
const waterImages = config.galery.images.lists.waterImages;

const heavyCurrentGalery = heavyCurrentImages.map((image)=> {
  return require('../../assets/images/galery/heavyCurrent/' + image);
});
const materialHandlingGalery = materialHandlingImages.map((image)=> {
  return require('../../assets/images/galery/materialHandling/' + image);
});
const waterGalery = waterImages.map((image)=> {
  return require('../../assets/images/galery/water/' + image);
});

export default {
  name: 'Galery',
  mounted () {
    window.scrollTo(0, 0);
  },
  data: function () {
    return {
      heavyCurrentImages,
      heavyCurrentGalery,
      materialHandlingImages,
      materialHandlingGalery,
      waterImages,
      waterGalery,
      hCIndex: null,
      mHIndex: null,
      wIndex: null,
    };
  },
  components: {
    H2Section,
    Section,
    VueGallery
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .image {
    display: inline-block;
    height: 125px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border: 2px solid #8d8d8d;
    border-radius: 3px;
  }

  .image:hover {
    cursor: pointer;
    border: 2px solid #00b4cd;
  }

  a.prev:hover {
    color: #00b4cd !important;
  }

  a.next:hover {
    color: #00b4cd !important;
  }

  a.close {
    color: #fff !important;
  }

  a.close:hover {
    color: #00b4cd !important;
  }
</style>
