<template>
  <h2 class="text-left mb-4">
    <div class="h2-section-indicator mr-1"></div>
    {{ $t(itemText) }}
  </h2>
</template>

<script>
export default {
  name: 'H2Section',
  props: {
    itemText: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .h2-section-indicator {
    width: 11px;
    height: 22px;
    background-color: #00b4cd;
    display: inline-block;
  }

  .h2-section-title {
    display: inline-block;
  }
</style>