<template>
  <div class="lang-selector">
    <img class="lang-icon" v-bind:class="{ selected: $i18n.locale === 'hu' }" v-on:click="changeLang('hu')" src="@/assets/images/flag-hungary.png"/>
    |
    <img class="lang-icon" v-bind:class="{ selected: $i18n.locale === 'en' }" v-on:click="changeLang('en')" src="@/assets/images/flag-united-kingdom.png"/>
  </div>
</template>

<script>
export default {
  name: 'LangSelector',
  props: {},
  methods: {
    changeLang(lang) {
      if (this.$i18n.locale !== lang) {
        this.$i18n.locale = lang;
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  @media (min-width: 768px) { 
    .lang-selector {
      color: #00b4cd;
      font-size: 35px;
      padding-bottom: 3px;
      padding-left: 5px;
      padding-right: 5px;
      background-color: #fff;
      border: 2px solid #8d8d8d;
      border-radius: 35px;
    }
  }

  @media (max-width: 767px) { 
    .lang-selector {
      color: #00b4cd;
      font-size: 35px;
      padding-bottom: 3px;
      padding-left: 5px;
      padding-right: 5px;
      background-color: #fff;
    }
  }

  .lang-icon {
    width: 35px;
    height: 35px;
    opacity: 0.5;
  }

  .lang-icon:hover:not(.selected) {
    cursor: pointer;
    opacity: 0.75;
  }

  .lang-icon.selected {
    cursor: default;
    opacity: 1.0;
  }
</style>