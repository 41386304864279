<template>
  <div class="pt-4 mb-4">
    <H2Section itemText="pages.company.aboutUsHeader"/>
    <p class="text-justify">
      {{ $t("pages.company.aboutUsContent") }}
    </p>
    <div class="mt-1 mb-4 text-left">
      <Section itemText="pages.company.activityHeader"/>
      <p class="ml-4 font-weight-bold">{{ $t("pages.company.activities.processControll") }}</p>
      <Section itemText="pages.company.specialitiesHeader"/>
      <p class="ml-4 mb-1 font-weight-bold">{{ $t("pages.company.specialities.plc") }}</p>
      <p class="ml-4 mb-1 font-weight-bold">{{ $t("pages.company.specialities.scada") }}</p>
      <p class="ml-4 mb-1 font-weight-bold">{{ $t("pages.company.specialities.custom") }}</p>
      <p class="ml-4 mb-1 font-weight-bold">{{ $t("pages.company.specialities.web") }}</p>
      <p class="ml-4 mb-0 font-weight-bold">{{ $t("pages.company.specialities.software") }}</p>
    </div>
  </div>
</template>

<script>
import H2Section from '../elements/H2Section.vue';
import Section from '../elements/Section.vue';

export default {
  name: 'Company',
  mounted () {
    window.scrollTo(0, 0);
  },
  components: {
    H2Section,
    Section
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
