<template>
  <div id="main-wrapper">
    <div id="main-content" class="container pb-3 circuits-corner">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainContent',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media (min-width: 768px) { 
  #main-wrapper {
    padding-top: 140px;
  }
}

@media (max-width: 767px) { 
  #main-wrapper {
    padding-top: 110px;
  }
}

  #main-content {
    border-left: 2px solid #8d8d8d;
    border-right: 2px solid #8d8d8d;
    border-bottom: 2px solid #8d8d8d;
    border-radius: 0px 0px 5px 5px;
    min-height: inherit;
  }
</style>