<template>
  <div class="pt-4 text-left">
    <Section itemText="pages.contacts.mailAddress"/>
    <p class="ml-4 mb-1 font-weight-bold">Neu-Soft Mérnöki Iroda Szolgáltató Kft.</p>
    <p class="ml-4 mb-1 font-weight-bold">6000 Kecskemét</p>
    <p class="ml-4 mb-1 font-weight-bold">Nyíri u. 38/C Fsz. 4.</p>
    <Section itemText="pages.contacts.gps"/>
    <p class="ml-4 mb-1 font-weight-bold">North 46° 54' 37,206"</p>
    <p class="ml-4 mb-1 font-weight-bold">East 19° 40' 30,744"</p>
    <p class="ml-4 mb-1 font-weight-bold">
      <a href="https://www.google.com/maps/place/46.910168471871536,%2019.67534615981413" target="_blank">
        Google Maps
      </a>
    </p>
    <Section itemText="pages.contacts.phone"/>
    <p class="ml-4 mb-1 font-weight-bold">
      <a href="tel:+3676509510">
        +36 76 509 510
      </a>
    </p>
    <p class="ml-4 mb-1 font-weight-bold">
      <a href="tel:+3676509511">
        +36 76 509 511
      </a>
    </p>
    <p class="ml-4 mb-1 font-weight-bold">
      <a href="tel:+36302391213">
        +36 30 239 1213
      </a>
    </p>
    <Section itemText="pages.contacts.fax"/>
    <p class="ml-4 mb-1 font-weight-bold">36-76-509-510</p>
    <Section itemText="pages.contacts.email"/>
    <p class="ml-4 mb-1 font-weight-bold">
      <a href="mailto:neusoft@neusoft.hu">
        neusoft@neusoft.hu
      </a>
    </p>
    <Section itemText="pages.contacts.web"/>
    <p class="ml-4 mb-1 font-weight-bold">
      <a href="https://www.neusoft.hu" target="_blank">
        www.neusoft.hu
      </a>
    </p>
  </div>
</template>

<script>
import Section from '../elements/Section.vue';

export default {
  name: 'Contacts',
  mounted () {
    window.scrollTo(0, 0);
  },
  components: {
    Section
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
