<template>
  <div class="pt-4 mb-4">
    <div>
      <H2Section itemText="pages.profile.plcMainHeader"/>
      <p class="text-justify">
        {{ $t("pages.profile.plcMainContent") }}
      </p>
      <div class="text-left">
        <p>{{ $t("pages.profile.plcSubContent") }}</p>
        <Section itemText="pages.profile.plcSubHeader"/>
        <p class="ml-4 mb-1 font-weight-bold">{{ $t("pages.profile.plcs.schneider") }}</p>
        <p class="ml-4 mb-1 font-weight-bold">{{ $t("pages.profile.plcs.omron") }}</p>
        <p class="ml-4 mb-1 font-weight-bold">{{ $t("pages.profile.plcs.moeller") }}</p>
        <p class="ml-4 mb-1 font-weight-bold">{{ $t("pages.profile.plcs.telemecanique") }}</p>
        <p class="ml-4 mb-1 font-weight-bold">{{ $t("pages.profile.plcs.saia") }}</p>
        <Section itemText="pages.profile.panelsSubHeader"/>
        <p class="ml-4 mb-1 font-weight-bold">{{ $t("pages.profile.panels.magelis") }}</p>
        <p class="ml-4 mb-1 font-weight-bold">{{ $t("pages.profile.panels.omron") }}</p>
      </div>
    </div>
    <div class="pt-4">
      <H2Section itemText="pages.profile.scadaMainHeader"/>
      <p class="text-justify">
        {{ $t("pages.profile.scadaMainContent") }}
      </p>
      <div class="text-left">
        <p>{{ $t("pages.profile.scadaSubContent") }}</p>
        <Section itemText="pages.profile.scadaSubHeader"/>
        <p class="ml-4 mb-1 font-weight-bold">{{ $t("pages.profile.scadas.scadaSys") }}</p>
        <p class="ml-4 mb-1 font-weight-bold">{{ $t("pages.profile.scadas.wonderware") }}</p>
        <p class="ml-4 mb-1 font-weight-bold">{{ $t("pages.profile.scadas.schneider") }}</p>
        <p class="ml-4 mb-1 font-weight-bold">{{ $t("pages.profile.scadas.provicon") }}</p>
        <p class="ml-4 mb-1 font-weight-bold">{{ $t("pages.profile.scadas.rockwell") }}</p>
        <p class="ml-4 mb-1 font-weight-bold">{{ $t("pages.profile.scadas.vijeo") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import H2Section from '../elements/H2Section.vue';
import Section from '../elements/Section.vue';

export default {
  name: 'Profile',
  mounted () {
    window.scrollTo(0, 0);
  },
  components: {
    H2Section,
    Section
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
