<template>
  <div class="pt-4 mb-4">
    <H2Section itemText="pages.reference.mainHeader"/>
    <Section itemText="pages.reference.heavyCurrent.header"/>
    <ReferenceBlock referenceWork="pages.reference.heavyCurrent.works[0]"/>
    <ReferenceBlock referenceWork="pages.reference.heavyCurrent.works[1]"/>
    <Section itemText="pages.reference.materialHandling.header"/>
    <ReferenceBlock referenceWork="pages.reference.materialHandling.works[0]"/>
    <Section itemText="pages.reference.foodIndustry.header"/>
    <ReferenceBlock referenceWork="pages.reference.foodIndustry.works[0]"/>
    <ReferenceBlock referenceWork="pages.reference.foodIndustry.works[1]"/>
    <ReferenceBlock referenceWork="pages.reference.foodIndustry.works[2]"/>
    <ReferenceBlock referenceWork="pages.reference.foodIndustry.works[3]"/>
    <Section itemText="pages.reference.water.header"/>
    <ReferenceBlock referenceWork="pages.reference.water.works[0]"/>
    <ReferenceBlock referenceWork="pages.reference.water.works[1]"/>
    <ReferenceBlock referenceWork="pages.reference.water.works[2]"/>
    <Section itemText="pages.reference.steelProduction.header"/>
    <ReferenceBlock referenceWork="pages.reference.steelProduction.works[0]"/>
    <ReferenceBlock referenceWork="pages.reference.steelProduction.works[1]"/>
    <ReferenceBlock referenceWork="pages.reference.steelProduction.works[2]"/>
    <Section itemText="pages.reference.dataCollection.header"/>
    <ReferenceBlock referenceWork="pages.reference.dataCollection.works[0]"/>
    <ReferenceBlock referenceWork="pages.reference.dataCollection.works[1]"/>
    <Section itemText="pages.reference.other.header"/>
    <ReferenceBlock referenceWork="pages.reference.other.works[0]"/>
    <ReferenceBlock referenceWork="pages.reference.other.works[1]"/>
    <ReferenceBlock referenceWork="pages.reference.other.works[2]"/>
  </div>
</template>

<script>
import Section from '../elements/Section.vue';
import ReferenceBlock from '../elements/ReferenceBlock.vue';
import H2Section from '../elements/H2Section.vue';

export default {
  name: 'Reference',
  mounted () {
    window.scrollTo(0, 0);
  },
  components: {
    H2Section,
    ReferenceBlock,
    Section
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
