module.exports = {
    webpage: {
        sql: {
            /*isEnabled: true,
            host: 'localhost',
            dbName: 'tomkovics',
            user: 'tomkovics',
            pwd: 'asdf4321'*/
            isEnabled: true,
            host: 'localhost',
            dbName: 'c765_neusoft',
            user: 'c765_neusoft',
            pwd: 'n3us0ft314'
        }
    },
    galery: {
        images: {
            lists: {
                heavyCurrentImages: [
                    '0001.jpeg',
                    '0002.jpeg',
                    '0003.jpg',
                    '0004.jpeg',
                    '0005.jpeg',
                    '0006.jpeg',
                    '0007.jpeg',
                    '0008.jpeg',
                    '0009.jpeg',
                    '0010.jpeg',
                    '0011.jpeg',
                    '0012.jpeg',
                    '0013.jpeg',
                    '0014.jpeg',
                    '0015.jpeg',
                    '0016.jpeg',
                    '0017.jpeg',
                    '0018.jpg'
                ],
                materialHandlingImages: [
                    '0001.jpg',
                    '0002.jpg',
                    '0003.jpg',
                    '0004.jpg',
                    '0005.jpg',
                    '0006.jpg',
                    '0007.jpg',
                    '0008.jpg',
                    '0009.jpg',
                    '0010.jpg',
                    '0011.jpg',
                    '0012.jpg',
                    '0013.jpg',
                    '0014.jpg'
                ],
                waterImages: [
                    '0001.jpg',
                    '0002.jpg',
                    '0003.jpg',
                    '0004.jpg',
                    '0005.jpg',
                    '0006.jpg',
                    '0007.jpg',
                    '0008.jpg',
                    '0009.jpg',
                    '0010.jpg',
                    '0011.jpg',
                    '0012.jpg',
                    '0013.jpg',
                    '0014.jpg',
                    '0015.jpg',
                    '0016.jpg',
                    '0017.jpg',
                    '0018.jpg'
                ]
            }
        }
    }
};