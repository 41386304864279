<template>
  <div class="text-left mb-2">
    <div class="section-indicator mr-1"></div>
    <div class="section-title">{{ $t(itemText) }}</div>
  </div>
</template>

<script>
export default {
  name: 'Section',
  props: {
    itemText: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .section-indicator {
    width: 11px;
    height: 11px;
    background-color: #00b4cd;
    display: inline-block;
  }

  .section-title {
    display: inline-block;
  }
</style>