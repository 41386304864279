<template>
  <div id="header-wrapper">
    <div id="header-background"></div>
    <div id="header-content">
      <div v-if=sqlEnabled id="header-visitors-count" class="d-none d-md-inline">
        <VisitorsCount :count="visitorsCount" />
      </div>
      <h1 class="font-weight-bold">{{ $t("header.companyName") }}</h1>
      {{ $t("header.companyDescription") }}
      <div id="header-lang-selector" class="d-none d-md-inline">
        <LangSelector/>
      </div>
    </div>
    <div id="header-separator"></div>
  </div>
</template>

<script>
import LangSelector from './elements/LangSelector.vue';
import VisitorsCount from './elements/VisitorsCount.vue';

export default {
  name: 'Header',
  components: {
    LangSelector,
    VisitorsCount
  },
  props: {
    sqlEnabled: Boolean,
    visitorsCount: Number
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  #header-wrapper {
    overflow: hidden;
    background-color: #fff;
    position: fixed;
    top: 0;
    width: 100%;
    z-index:11;
  }

  #header-background {
    background: url('~@/assets/images/circuits02.jpg');
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 80px;
  }

  #header-content {
    position: relative;
  }

  #header-separator {
    width: 100%;
    height: 2px;
    background-color: #00b4cd;
    opacity: 0.7;
  }

  #header-lang-selector {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  #header-visitors-count {
    position: absolute;
    left: 5px;
    top: 45px;
  }
</style>