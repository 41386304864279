module.exports = {
    header: {
        companyName: 'Neu-Soft',
        companyDescription: 'Mérnöki Iroda Szolgáltató Kft.',
        visitors: 'Visitors'
    },
    menu: {
        company: 'Company',
        contacts: 'Contacts',
        galery: 'Galery',
        profile: 'Profile',
        reference: 'References'
    },
    pages: {
        company: {
            aboutUsHeader: 'About us',
            aboutUsContent: 'The Neu-Soft Ltd. was founded in January 2001 in Kecskemét, Hungary. Our colleagues are all graduated, they have considerable professional experience in development and system installation in many areas of the industry. Our partners\' contentment is very important for us, so we place great emphasis on the personal relationships with them. If you have any question please send it to Imre Neumüller (neumuller@neusoft.hu)',
            activityHeader: 'Our primary activity:',
            activities: {
                processControll: 'Industrial process control & supervision'
            },
            specialitiesHeader: 'Our specialities:',
            specialities: {
                plc: 'PLC programming',
                scada: 'Building of SCADA applications',
                custom: 'Programming of custom devices',
                web: 'SQL & WEB development',
                software: 'Software development on MS Windows, QNX, UNIX operating systems'
            },
        },
        contacts: {
            mailAddress: 'Mail address',
            gps: 'GPS',
            phone: 'Phone',
            fax: 'Fax',
            email: 'E-mail',
            web: 'Web'
        },
        galery: {
            mainHeader:'Galery',
            heavyCurrent: 'Heavy-current',
            materialHandling: 'Material handling',
            water: 'Water and waste water'
        },
        profile: {
            plcMainHeader: 'PLC and operator panel programming',
            plcMainContent: 'We developed both single processor and more difficult systems with networked controllers. We accomplished the control and visualization using SCADA softwares or operator panels by demand. We used many of communication protocols like Modbus, ModbusPlus, JBus, Profibus etc.',
            plcSubContent: 'We have significant practice in programming of the following PLC families and operator panels:',
            plcSubHeader: 'Controllers:',
            plcs:{
                schneider: 'Schenider Modicon PLC',
                omron: 'Omron PLC',
                moeller: 'Moeller PLC',
                telemecanique: 'Telemecanique Premium PLC',
                saia: 'Saia PLC'
            },
            panelsSubHeader: 'Panels:',
            panels: {
                magelis: 'Schneider Magelis',
                omron: 'Omron TouchPanel'
            },
            scadaMainHeader: 'SCADA application development',
            scadaMainContent: 'We built both simple and networked applications with more workstations. The more complicated systems communicated with different PLC configurations and some cases it had connection to upper-level systems.',
            scadaSubContent: 'We are experienced in the following softwares:',
            scadaSubHeader: 'SCADA applications:',
            scadas: {
                scadaSys: 'ScadaSys Dmon',
                wonderware: 'Wonderware InTouch',
                schneider: 'Schneider MonitorPro',
                provicon: 'Provicon Vision',
                rockwell: 'Rockwell RS-View',
                vijeo: 'Vijeo Citect'
            }
        },
        reference: {
            mainHeader: 'Reference works',
            heavyCurrent: {
                header: 'Heavy-current',
                works: [
                    {
                        locationText: 'OPZ. Odessa, Ukraine',
                        workText: 'Heavy-current switching equipment',
                        developmentText: 'Control and data collection: TSX Premium PLC',
                        extraText: 'Operator interface: Schneider MonitorPro SCADA system'
                    },
                    {
                        locationText: 'Kiev, ukraine',
                        workText: 'Heavy-current switching equipment',
                        developmentText: 'Control and data collection: TSX Premium PLC',
                        extraText: 'Operator interface: Schneider Magelis panel'
                    }
                ]
            },
            materialHandling: {
                header: 'Material handling',
                works: [
                    {
                        locationText: 'Mester Traverz Kft. Kecskemét',
                        workText: 'Automatic material handling for the steel structure production',
                        developmentText: 'Control and data collection: Moeller PLC',
                        extraText: ''
                    }
                ]
            },
            foodIndustry: {
                header: 'Food industry',
                works: [
                    {
                        locationText: 'Masterfoods Magyarország Gyártó Kft. Bokros',
                        workText: 'Application for an biscuit producing oven line',
                        developmentText: 'Control and data collection: Modicon Quantum PLC',
                        extraText: 'Operator interface: Scheider Magelis panel, Wonderware InTouch SCADA system'
                    },
                    {
                        locationText: 'Masterfoods Magyarország Gyártó Kft. Bokros',
                        workText: 'Automation of the weighing system of the dry ingredients',
                        developmentText: 'Control and data collection: Modicon Quantum PLC',
                        extraText: 'Operator interface: Scheider Magelis panel, Wonderware InTouch SCADA system'
                    },
                    {
                        locationText: 'Masterfoods Magyarország Gyártó Kft. Bokros',
                        workText: 'Automatic material handling for the steel structure production',
                        developmentText: 'Control and data collection: Modicon Quantum PLC',
                        extraText: 'Operator interface: Wonderware InTouch SCADA system'
                    },
                    {
                        locationText: 'Masterfoods Magyarország Gyártó Kft. Bokros',
                        workText: 'Automatic material handling for the steel structure production',
                        developmentText: 'Control and data collection: Moeller PLC',
                        extraText: 'Operator interface: WEB (HTML with PHP)'
                    }
                ]
            },
            water: {
                header: 'Water and waste water',
                works: [
                    {
                        locationText: 'Waste water plant in Hajdúnánás',
                        workText: '',
                        developmentText: 'Control and data collection: Modicon Momentum PLC',
                        extraText: 'Operator interface: D-mon SCADA system'
                    },
                    {
                        locationText: 'Waste water plant in Madocsa',
                        workText: '',
                        developmentText: 'Control and data collection: Saia PLC',
                        extraText: ''
                    },
                    {
                        locationText: 'Waste water plant in Nagykörös (KÖVA Rt.)',
                        workText: '',
                        developmentText: 'Control and data collection: TSX Premium PLC',
                        extraText: 'Operator interface: Vision-7 SCADA system'
                    }
                ]
            },
            steelProduction: {
                header: 'Steel production',
                works: [
                    {
                        locationText: 'Acélmű Kft. Dunaújváros',
                        workText: 'Three-state cauldron metallurgy system',
                        developmentText: 'Control and data collection: Omron PLC',
                        extraText: 'Operator interface: Omron TouchPanel'
                    },
                    {
                        locationText: 'Acélmű Kft. Dunaújváros',
                        workText: 'Automation of a crude iron mixer',
                        developmentText: 'Control and data collection: Omron PLC',
                        extraText: 'Operator interface: Omron TouchPanel'
                    },
                    {
                        locationText: 'Acélmű Kft. Dunaújváros',
                        workText: 'Automation of a steel production converter',
                        developmentText: 'Control and data collection: Omron PLC',
                        extraText: 'Operator interface: Omron TouchPanel'
                    },
                ]
            },
            dataCollection: {
                header: 'Data collection',
                works: [
                    {
                        locationText: 'Knorr-Bremse Kft. Kecskemét',
                        workText: 'Bench checking system',
                        developmentText: 'Data collection: custom solution',
                        extraText: 'Operator interface: Vision-7 SCADA system'
                    },
                    {
                        locationText: 'M-Real Petőfi Nyomda Kft. Kecskemét',
                        workText: 'Data collection from printing equipments',
                        developmentText: 'Data collection: Twido PLC' ,
                        extraText: 'Operator interface: Vision-7 SCADA system'
                    }
                ]
            },
            other: {
                header: 'Other',
                works: [
                    {
                        locationText: 'DRV Siófok',
                        workText: 'Expert system for controlling limited nitrate dosing (waste water plant)',
                        developmentText: 'Regulator subsystem',
                        extraText: '(As subcontractor of ScadaSys Kft.)'
                    },
                    {
                        locationText: 'Russia',
                        workText: 'Supervisory system (telemechanic) of Baltic pipeline system',
                        developmentText: 'Units for doing special calculations',
                        extraText: '(As subcontractor of ScadaSys Kft.)'
                    },
                    {
                        locationText: 'Budapest University of Technology and Economics',
                        workText: 'Controlling of the power supply system',
                        developmentText: 'Subsystem for controlling commutator switches',
                        extraText: '(As subcontractor of ScadaSys Kft.)'
                    }
                ]
            }

        }
    }
};
