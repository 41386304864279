<template>
  <div class="text-left mb-2 ml-4 pl-2 reference-block-side">
    <p class="font-weight-bold mt-0 mb-0">{{ $t(referenceWork + ".locationText") }}</p>
    <p class="font-weight-bold mt-0 mb-0">{{ $t(referenceWork + ".workText") }}</p>
    <p class="mt-0 mb-0">{{ $t(referenceWork + ".developmentText") }}</p>
    <p class="mt-0 mb-0">{{ $t(referenceWork + ".extraText") }}</p>
  </div>
</template>

<script>
export default {
  name: 'ReferenceBlock',
  props: {
    referenceWork: String,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .reference-block-side {
      border-left: 2px solid #00b4cd;
  }
</style>