module.exports = {
    header: {
        companyName: 'Neu-Soft',
        companyDescription: 'Mérnöki Iroda Szolgáltató Kft.',
        visitors: 'Látogatók'
    },
    menu: {
        company: 'Céginformáció',
        contacts: 'Kapcsolat',
        galery: 'Képgaléria',
        profile: 'Tevékenységek',
        reference: 'Referenciák'
    },
    pages: {
        company: {
            aboutUsHeader: 'Rólunk',
            aboutUsContent: 'A Neu-Soft Kft. 2001 év januárjában alakult Kecskeméten. Munkatársaink felsőfokú végzettségűek, jelentős szakmai gyakorlattal rendelkeznek, fejlesztési és beüzemelési feladatok megoldásában, az ipar számos területén. Számunkra fontos partnereink elégedettsége, ezért munkánk minősége mellett nagy súlyt fektetünk a partnereinkkel kialakított emberi kapcsolatokra is. Kérdéseivel forduljon Neumüller Imréhez (neumuller@neusoft.hu)',
            activityHeader: 'Alapvető tevékenységünk:',
            activities: {
                processControll: 'Ipari folyamatirányítás és felügyelet'
            },
            specialitiesHeader: 'Vállalkozásunk körébe tartozik:',
            specialities: {
                plc: 'PLC programozás',
                scada: 'Scada rendszer alkalmazás készítése',
                custom: 'Egyedi eszközök programozása',
                web: 'SQL és WEB programozás',
                software: 'Software fejlesztés Windows, QNX, UNIX operációs rendszerekre'
            },
        },
        contacts: {
            mailAddress: 'Levelezési cím',
            gps: 'GPS',
            phone: 'Telefon',
            fax: 'Fax',
            email: 'E-mail',
            web: 'Web'
        },
        galery: {
            mainHeader:'Képgaléria',
            heavyCurrent: 'Erősáram',
            materialHandling: 'Anyagmozgatás',
            water: 'Víz-Szennyvíz'
        },
        profile: {
            plcMainHeader: 'PLC és kezelői panel programozás',
            plcMainContent: 'Különböző PLC-s rendszerek elkészítésében vettünk részt. Készítettünk egyszerű, egy PLC-ből álló, illetve nagy, hálózatba kötött PLC-kből álló rendszereket. Az ipari folyamatirányítás igényeinek megfelelően, a technológiák megjelenítését és kezelését kezelői terminálokkal illetve scada rendszerekkel valósítottuk meg. A rendszer kialakításoknál különböző kommunikációs protokolokat használtunk, mint pl. Modbus, ModbusPlus, JBus, Profibus, ...',
            plcSubContent: 'Jelentős gyakorlattal rendelkezünk az alábbi PLC családok és kezelői terminálok programozásában:',
            plcSubHeader: 'PLC programozás:',
            plcs:{
                schneider: 'Schenider Modicon PLC',
                omron: 'Omron PLC',
                moeller: 'Moeller PLC',
                telemecanique: 'Telemecanique Premium PLC',
                saia: 'Saia PLC'
            },
            panelsSubHeader: 'Panelek:',
            panels: {
                magelis: 'Schneider Magelis',
                omron: 'Omron TouchPanel'
            },
            scadaMainHeader: 'Scada rendszer programozás',
            scadaMainContent: 'Különböző scada rendszerek elkészítésében vettünk részt. Készítettünk egyszerű, egy kezelői munkahellyel rendelkező rendszereket, illetve nagy, hálózatba kötött applikációkat is. Rendszerek különböző PLC konfigurációkkal kommunikáltak, illetve felsőbb szintű rendszerekkel tartottak kapcsolatot.',
            scadaSubContent: 'Gyakorlattal rendelkezünk az alábbiak programozásában:',
            scadaSubHeader: 'Scada rendszerek:',
            scadas: {
                scadaSys: 'ScadaSys Dmon',
                wonderware: 'Wonderware InTouch',
                schneider: 'Schneider MonitorPro',
                provicon: 'Provicon Vision',
                rockwell: 'Rockwell RS-View',
                vijeo: 'Vijeo Citect'
            }
        },
        reference: {
            mainHeader: 'Referencia munkáink',
            heavyCurrent: {
                header: 'Erősáram',
                works: [
                    {
                        locationText: 'OPZ. Ukrajna Odessa',
                        workText: 'Erősáramú elosztó rendszer',
                        developmentText: 'Vezérlés és adatgyűjtés: TSX Premium PLC',
                        extraText: 'Kezelői felület: MonitorPro scada rendszer'
                    },
                    {
                        locationText: 'Ukrajna Kiev',
                        workText: 'Erősáramú elosztó rendszer',
                        developmentText: 'Vezérlés és adatgyűjtés: TSX Premium PLC',
                        extraText: 'Kezelői felület: Magelis terminál'
                    }
                ]
            },
            materialHandling: {
                header: 'Anyagmozgatás',
                works: [
                    {
                        locationText: 'Mester Traverz Kft. Kecskemét',
                        workText: 'Vasipari automatikus anyagmozgató rendszer',
                        developmentText: 'Vezérlés és adatgyűjtés: Moeller PLC',
                        extraText: ''
                    }
                ]
            },
            foodIndustry: {
                header: 'Élelmiszeripar',
                works: [
                    {
                        locationText: 'Masterfoods Magyararország Gyártó Kft. Bokros',
                        workText: 'Kekszgyártó kemence sor vezérlő rendszere',
                        developmentText: 'Vezérlés és adatgyűjtés: Modicom Quantum PLC',
                        extraText: 'Kezelői felület: Magelis terminál, InTouch scada rendszer'
                    },
                    {
                        locationText: 'Masterfoods Magyararország Gyártó Kft. Bokros',
                        workText: 'Malom vezérlő rendszere',
                        developmentText: 'Vezérlés és adatgyűjtés: Modicom Quantum PLC',
                        extraText: 'Kezelői felület: Magelis terminál, InTouch scada rendszer'
                    },
                    {
                        locationText: 'Masterfoods Magyararország Gyártó Kft. Bokros',
                        workText: 'Por összemérő és adagoló vezérlő rendszer',
                        developmentText: 'Vezérlés és adatgyűjtés: Modicom Quantum PLC',
                        extraText: 'Kezelői felület: InTouch scada rendszer'
                    },
                    {
                        locationText: 'Masterfoods Magyararország Gyártó Kft. Bokros',
                        workText: 'Központi adatgyűjtő és konfiguráló rendszer',
                        developmentText: 'Adatbázis: Oracle és MSSQL',
                        extraText: 'Kezelői felület: WEB (HTML és PHP)'
                    }
                ]
            },
            water: {
                header: 'Víz-Szennyvíz',
                works: [
                    {
                        locationText: 'Szennyvíztisztító telep Hajdúnánás',
                        workText: '',
                        developmentText: 'Vezérlés és adatgyűjtés: Modicon Momentum PLC',
                        extraText: 'Kezelői felület: D-mon scada rendszer'
                    },
                    {
                        locationText: 'Szennyvíztisztító telep Madocsa',
                        workText: '',
                        developmentText: 'Vezérlés és adatgyűjtés: Saia PLC',
                        extraText: ''
                    },
                    {
                        locationText: 'Szennyvíztisztító telep Nagykörös (KÖVA Rt.)',
                        workText: '',
                        developmentText: 'Vezérlés és adatgyűjtés: TSX Premium PLC',
                        extraText: 'Kezelői felület: Vision-7 scada rendszer'
                    }
                ]
            },
            steelProduction: {
                header: 'Acélgyártás',
                works: [
                    {
                        locationText: 'Acélmű Kft. Dunaújváros',
                        workText: 'Háromállásos üstmetallurgia rendszer',
                        developmentText: 'Vezérlés és adatgyűjtés: Omron PLC',
                        extraText: 'Helyi kezelői felület: Omron TouchPanel'
                    },
                    {
                        locationText: 'Acélmű Kft. Dunaújváros',
                        workText: 'Nyersvaskeverő automatizálási rendszer',
                        developmentText: 'Vezérlés és adatgyűjtés: Omron PLC',
                        extraText: 'Helyi kezelői felület: Omron TouchPanel'
                    },
                    {
                        locationText: 'Acélmű Kft. Dunaújváros',
                        workText: 'Acélgyártó konverter automatizálási rendszere',
                        developmentText: 'Vezérlés és adatgyűjtés: Omron PLC',
                        extraText: 'Helyi kezelői felület: Omron TouchPanel'
                    }
                ]
            },
            dataCollection: {
                header: 'Adatgyűjtés',
                works: [
                    {
                        locationText: 'Knorr-Bremse Kft. Kecskemét',
                        workText: 'Mérőpad ellenörző rendszer',
                        developmentText: 'Adatgyűjtés: egyedi megoldás',
                        extraText: 'Kezelői felület: Vision-7 scada rendszer'
                    },
                    {
                        locationText: 'M-Real Petőfi Nyomda Kft. Kecskemét',
                        workText: 'Adatgyűjtés nyomdaipari gépekről',
                        developmentText: 'Adatgyűjtés: Twido PLC',
                        extraText: 'Kezelői felület: Vision-7 scada rendszer'
                    }
                ]
            },
            other: {
                header: 'Egyéb',
                works: [
                    {
                        locationText: 'DRV Siófok',
                        workText: 'Limitált nitrát adagolást felügyelő szakértői rendszer (szennyvíz telep)',
                        developmentText: 'Szabályozó alrendszer megvalósítása',
                        extraText: '(ScadaSys Kft. alvállalkozójaként vettünk részt)'
                    },
                    {
                        locationText: 'Oroszország',
                        workText: 'Balti Csővezeték Telemechanika rendszer',
                        developmentText: 'Speciális számításokat végző alrendszer megvalósítása',
                        extraText: '(ScadaSys Kft. alvállalkozójaként vettünk részt)'
                    },
                    {
                        locationText: 'BME Budapest',
                        workText: 'Erősáramú felügyeleti rendszer',
                        developmentText: 'Átkapcsoló automatikákat végző alrendszer megvalósítása',
                        extraText: '(ScadaSys Kft. alvállalkozójaként vettünk részt)'
                    }
                ]
            }

        }
    }
};
