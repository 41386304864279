<template>
  <div id="app">
    <Header :sqlEnabled="sqlEnabled" :visitorsCount="visitorsCount" />
    <Menu :sqlEnabled="sqlEnabled" :visitorsCount="visitorsCount" />
    <Content/>
  </div>
</template>

<script>
import axios from 'axios';
import * as config from './assets/config.js';
import Header from './components/Header.vue';
import Menu from './components/Menu.vue'
import Content from './components/Content.vue'

export default {
  name: 'App',
  components: {
    Header,
    Menu,
    Content
  },
  data: function () {
    return {
      sqlEnabled: config.webpage.sql.isEnabled,
      visitorsCount: 0
    };
  },
  created: function () {
    const self = this;
    if (config.webpage.sql.isEnabled) {
      axios.post('visitors.php', {
        action:'update',
        host: config.webpage.sql.host,
        dbName: config.webpage.sql.dbName,
        user: config.webpage.sql.user,
        pwd: config.webpage.sql.pwd
      }).then(function(response){
        self.visitorsCount = response.data;
      }).catch(function (err) {
        console.error(err);
        self.visitorsCount = 0;
      });
    }
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.circuits-corner {
  background: url('~@/assets/images/circuits02-bottom.jpg');
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: bottom;
  background-size: 185px;
}

@import'~bootstrap/dist/css/bootstrap.css'
</style>
