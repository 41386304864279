<template>
  <div class="visitors-border">
    <div class="">{{ $t("header.visitors") }}: {{ count }}</div>
  </div>
</template>

<script>
export default {
  name: 'VisitorsCount',
  props: {
    count: Number
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  @media (min-width: 768px) { 
    .visitors-border {
      padding-left: 5px;
      padding-right: 5px;
      background-color: #fff;
      border: 2px solid #8d8d8d;
      border-radius: 35px;
    }
  }

  @media (max-width: 767px) { 
    .visitors-border {
      text-align: center;
      margin-bottom: 5px;
    }
  }
</style>