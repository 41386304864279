<template>
  <div class="sticky-menu">
    <div id="sidemenu-button" class="d-flex d-md-none row no-gutters justify-content-center" v-on:click="changeSideMenuVisibility()"
      v-bind:class="{ opened: sideMenuVisible }">
      <div class="col-1">
        <div class="gray-line" v-bind:class="{ invisible: sideMenuVisible }"></div>
        <div class="gray-line" v-bind:class="{ invisible: sideMenuVisible }"></div>
        <div class="gray-line" v-bind:class="{ invisible: sideMenuVisible }"></div>
        <div class="gray-arrow" v-bind:class="{ invisible: !sideMenuVisible }"></div>
      </div>
    </div>
    <div id="main-menu" class="row no-gutters justify-content-center d-none d-md-flex">
      <MenuItem itemText="company" routerText="company"/>
      <MenuItem itemText="profile" routerText="profile"/>
      <MenuItem itemText="reference" routerText="reference"/>
      <MenuItem itemText="galery" routerText="galery"/>
      <MenuItem itemText="contacts" routerText="contacts"/>
    </div>
    <div id="side-menu" v-bind:class="{ invisible: !sideMenuVisible }" class="row no-gutters justify-content-center d-flex d-md-none">
      <MenuItem itemText="company" routerText="company"/>
      <MenuItem itemText="profile" routerText="profile"/>
      <MenuItem itemText="reference" routerText="reference"/>
      <MenuItem itemText="galery" routerText="galery"/>
      <MenuItem itemText="contacts" routerText="contacts"/>
      <div class="col-4 mb-3 mt-3">
        <LangSelector/>
      </div>
      <div v-if=sqlEnabled class="col-12">
        <VisitorsCount :count="visitorsCount" />
      </div>
    </div>
  </div>
</template>

<script>
import MenuItem from './elements/MenuItem.vue';
import LangSelector from './elements/LangSelector.vue';
import VisitorsCount from './elements/VisitorsCount.vue';

export default {
  name: 'MainMenu',
  components: {
    MenuItem,
    LangSelector,
    VisitorsCount
  },
  props: {
    sqlEnabled: Boolean,
    visitorsCount: Number
  },
  data: function () {
    return {
      sideMenuVisible: false
    };
  },
  methods: {
    changeSideMenuVisibility() {
      this.sideMenuVisible = !this.sideMenuVisible;
    }
  },
  watch:{
    $route (){
        this.sideMenuVisible = false;
    }
  } 
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media (min-width: 768px) { 
  #main-menu {
    border-bottom: 2px solid #8d8d8d;
    box-shadow: 0px 3px 25px 0px;
    background-color: #fff;
  }

  .sticky-menu {
    position: fixed;
    top: 82px;
    width: 100%;
    z-index: 10;
  }
}

@media (max-width: 767px) { 
  #side-menu {
    border-bottom: 2px solid #8d8d8d;
    box-shadow: 0px 10px 15px 0px;
    background-color: #fff;
  }

  .sticky-menu {
    position: fixed;
    top: 82px;
    width: 100%;
    z-index: 10;
  }

  #sidemenu-button {
    height: 30px;
    padding-top: 2px;
    border-bottom: 2px solid #8d8d8d;
    box-shadow: 0px 3px 25px 0px;
    background-color: #fff;
  }

  #sidemenu-button.opened {
    border-bottom: none !important;
  }

  .gray-line {
    height:5px;
    margin-bottom: 3px;
    width: 105%;
    background-color: #8d8d8d;
  }

  .gray-arrow {
      transform: rotateY(0deg) rotate(-45deg);
      height: 28px;
      width: 28px;
      margin-top: -18px;
      border-top: 3px solid #8d8d8d;
      border-right: 3px solid #8d8d8d;
  }
}
</style>